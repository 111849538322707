
import { defineComponent } from 'vue'
import { Input } from '@progress/kendo-vue-inputs'

export default defineComponent({
  components: {
    Input
  },
  setup() {
    return {}
  }
})
